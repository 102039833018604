window.addMultipleListener = function (element, eventNames, listenerFunction, useCapture = false) {
  const events = eventNames.split(' ')
  for (let event of events) {
    element.addEventListener(event, listenerFunction, useCapture)
  }
}


document.onkeypress = (e) => {
  // Disable Enter key on forms & search bar
  if(e) {
    const node  = (e.target) ? e.target : ((e.srcElement) ? e.srcElement : null)
    const input = node.type == 'text' || node.type == 'search'
    const isMobile = (app && app.device) ? (app.device.device == 'mobile' ? true : false) : false

    if (e.keyCode == 13 && input) {
      if (isMobile && node.classList.contains('blur-on-mobile-enter')) {
        if(app && app.components.inputSearch) {
          app.components.inputSearch.blur(node)
        }
        return false
      } else if (!node.classList.contains('allow-enter')) {
        return false
      }
    }
  }
}

window.getRandomNumberBetween = function(min = 0, max = 100) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
}

window.getRandomNumberDivisibleBy = function(min, max, divisibleBy = 10) {
  return getRandomNumberBetween(min, max) * divisibleBy
}

window.removeAllChild = function(parent) {
  if(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild)
    }
  }
}

window.insertAfter = function(el, refNode) {
  refNode.parentNode.insertBefore(el, refNode.nextSibling)
}

window.location.replaceQueryParam = function (name, path, value) {
  // Find the param with regex
  // Grab the first character in the returned path (should be ? or &)
  // Replace our path with our new value, passing on the name and delimiter

  var re = new RegExp("[\\?&]" + name + "=([^&#]*)")
  var matches = re.exec(path)
  var newUrl

  if (matches === null) {
      // if there are no params, append the parameter
      newUrl = path + '?' + name + '=' + value
  } else {
      var delimiter = matches[0].charAt(0)
      newUrl = path.replace(re, delimiter + name + "=" + value)
  }

  window.location.href = newUrl
}

window.displayAlert = alert => {
  app.components.alert.check(alert)
}

window.browserAllowDragAndDrop = () => {
  const div = document.createElement( 'div' )
  return (( 'draggable' in div ) || ( 'ondragstart' in div && 'ondrop' in div )) && 'FormData' in window && 'FileReader' in window
}


window.scrollToId = (id, dataId = false) => {
  if(!dataId) {
    const element = document.getElementById(id)
    if (element) element.scrollIntoView({ behavior: 'smooth' })
  } else {
    const element = document.querySelector(`[data-id="${id}"]`)
    if (element) element.scrollIntoView({ behavior: 'smooth' })
  }
}

window.checkUrl = () => {
  const hash = window.location.hash
  if(hash.length > 0) scrollToId(hash.split('#')[1])
}

window.checkInnerLinks = () => {
  const links = document.querySelectorAll('a[href="^#"]')
  for(let link of links) {
    link.addEventListener('click', () => {
      scrollToId(link.href.split('#')[1])
    })
  }
}

// Changes an elements's position in array
window.arrayMove = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0])
}

// Clamps a value to a min/max
window.clamp = (value, a, b) => {
  return value < a ? a : (value > b ? b : value)
}

window.pleaseWait = (enabled = true) => {
  document.body.setAttribute('aria-disabled', enabled)
}

window.pleaseWaitAutoDetection = () => {
  const items = document.getElementsByClassName('please-wait-trigger');
  for(let item of items) {
    item.addEventListener('click', (e) => {
      pleaseWait()
    })
  }
}

// window.checkSearchParamExistsWithValue = function(paramName) {
//   const searchParams = new URLSearchParams(window.location.search);
//   console.log("searchParams", searchParams)
// 	if (searchParams.has(paramName)) {
// 		const value = searchParams.get(paramName);
// 		return (value !== null && value !== '0' && value !== '') ? value : false;
// 	}
// 	return false;
// }
